import "./index.css";
import React, { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../layouts/authentication/components/BasicLayout";

// GraphQL Apollo Functions
import { useAuthState } from "../../context/Auth";
import { LoginUser } from "../../apollo/query/UserQuery";

import login from "assets/login.png";

function Basic() {
  const [errors, setErrors] = React.useState({});
  const [variables, setVariables] = React.useState({
    remember: false,
    email: "",
    password: "",
  });

  let { loginUser, loading } = LoginUser(setErrors);
  const handleSetRememberMe = () => setVariables({ ...variables, remember: !variables.remember });

  const submitLoginForm = (e) => {
    e.preventDefault();

    loginUser({ variables });
  };

  // Check if user is logged in already, and redirect back to dashboard
  const { user } = useAuthState();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  return (
    <BasicLayout image={login}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox onSubmit={submitLoginForm} component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                className={errors.email && "text-red"}
                onChange={(e) => setVariables({ ...variables, email: e.target.value })}
                type="email"
                label={errors.email ?? "Email"}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                className={errors.password && "text-red"}
                onChange={(e) => setVariables({ ...variables, password: e.target.value })}
                type="password"
                label={errors.password ?? "Password"}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={variables.remember} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="input" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={0} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={0} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                forgot your password?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Recovery
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
