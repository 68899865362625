import { gql, useMutation } from "@apollo/client";
import { useDocumentDispatch } from "context/Documents";
import { useNavigate } from "react-router-dom";

// import { NetworkIssues } from "../../components/allerrors/Issues";
// Apollo User Mutations

const CREATE_DOCUMENT = gql`
  mutation Mutation($type: String) {
    createDocument(type: $type) {
      id
      type
      title
      content
      isPublic
      updatedAt
      shareLink
      createdAt
      background
      lastEdited
      lastViewed
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  mutation Mutation(
    $id: Int!
    $content: String
    $isPublic: Boolean
    $title: String
    $background: String
  ) {
    updateDocument(
      id: $id
      content: $content
      isPublic: $isPublic
      title: $title
      background: $background
    ) {
      id
      type
      title
      content
      isPublic
      updatedAt
      shareLink
      createdAt
      background
      lastEdited
      lastViewed
    }
  }
`;

const DELETE_DOCUMENT = gql`
  mutation Mutation($id: Int!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

export const CreateDocument = (setErrors) => {
  const dispatch = useDocumentDispatch();
  const navigate = useNavigate();
  const [createDocument, { loading: createLoading }] = useMutation(CREATE_DOCUMENT, {
    // eslint-disable-next-line no-unused-vars
    update: (_, mutationResult) => {
      const valueReturned = mutationResult.data.createDocument;
      dispatch({ type: "CREATE", payload: valueReturned });
      navigate(`/page/${valueReturned.id}`, { replace: true });
    },
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
  });

  return { createDocument, createLoading };
};

export const UpdateDocument = (setErrors) => {
  const dispatch = useDocumentDispatch();
  const [updateDocument, { loading: updateLoading }] = useMutation(UPDATE_DOCUMENT, {
    // eslint-disable-next-line no-unused-vars
    update: (_, mutationResult) => {
      const valueReturned = mutationResult.data.updateDocument;
      dispatch({ type: "UPDATE", payload: valueReturned });
      //   setErrors(valueReturned);
    },
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
  });

  return { updateDocument, updateLoading };
};

export const DeleteDocument = (setErrors) => {
  const dispatch = useDocumentDispatch();
  const [deleteDocument, { loading: deleteLoading }] = useMutation(DELETE_DOCUMENT, {
    // eslint-disable-next-line no-unused-vars
    update: (_, mutationResult) => {
      const valueReturned = mutationResult.data.deleteDocument;
      //   setErrors(valueReturned);
      // Update documents state
      dispatch({ type: "DELETE", payload: valueReturned });
    },
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
  });

  return { deleteDocument, deleteLoading };
};
