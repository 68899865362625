import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Landpage from "./components/landpage/Landpage";
import AuthenticatedRoute from "./util/AuthenticatedRoute";

import SignIn from "components/Sign/SignIn";
import SignUp from "components/Sign/SignUp";
import Dashboard from "pages/Dashboard";

import routes from "./Routes";
import theme from "./assets/theme";

import { useAuthState } from "context/Auth";
import { ThemeProvider } from "@mui/material/styles";
import ResetPass from "./components/Sign/ResetPass";
import { useMaterialUIController } from "context";
import HandlePayment from "pages/HandlePayment";

function App() {
  const { user } = useAuthState();
  const { pathname } = useLocation();
  const [controller] = useMaterialUIController();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<AuthenticatedRoute authenticated={route.authenticated} />}
            key={route.key}
          >
            <Route exact path={route.route} element={route.component} />
          </Route>
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {getRoutes(routes)}

        <Route
          path="/"
          element={user?.subscriptionType === "subscription" ? <Dashboard /> : <Landpage />}
        />
        <Route exact path="/:id" element={<HandlePayment />} />
        <Route path="/authentication/sign-in" element={<SignIn />} />
        <Route path="/authentication/sign-up" element={<SignUp />} />
        <Route exact path="/authentication/sign-up/:id" element={<SignUp />} />
        <Route path="/authentication/reset-password" element={<ResetPass />} />
        <Route exact path="/authentication/reset-password/:id" element={<ResetPass />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
