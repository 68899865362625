import React from "react";
import Feature from "../../components/feature/Feature";
import "./whatGPT3.css";

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="The Power of FinishEssay"
        text="FinishEssay was created as a writing bot that simulates a human's personality. This product provides a level of creativity, flexibility and emotional intelligence that has never existed before.

        FinishEssay is powered by an artificial intelligence construct that writes essays, proposals, articles and other documents autonomously using natural language processing algorithms to produce papers in the style of any author."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Product Overview</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Faster Writing"
        text="you can write more in less time, turn sentences into paragraphs and paragraphs into chapters."
      />
      <Feature
        title="Knowledgebase"
        text="You can learn about a topic in seconds by typing it into FinishEssay and voilà, you have all the information in front of you."
      />
      <Feature
        title="Explore Concepts"
        text="You can be a better writer. No matter how good you are at writing, you can always find ways to improve.
        Think of it like an editor, every writer needs an editor, FinishEssay will help you with that. It's fun to use and very easy to learn."
      />
    </div>
  </div>
);

export default WhatGPT3;
