import React from "react";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import SortIcon from "@mui/icons-material/Sort";
import { Link, useNavigate } from "react-router-dom";
import { DeleteDocument } from "apollo/mutation/DocumentMutation";

function DocumentCard({ documents }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const open = Boolean(anchorEl);

  const { deleteDocument, deleteLoading } = DeleteDocument(setErrors);
  const [variables, setVariables] = React.useState({});
  const [sorting, setSorting] = React.useState();
  const [search, setSearch] = React.useState("");

  const handleMenu = (event) => {
    event.menu.stopPropagation();
    setVariables({ id: event.cardId });
    setAnchorEl(event.menu.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleCard = (id) => {
    navigate(`/page/${id}`);
  };

  const handleDelete = () => {
    deleteDocument({ variables });
  };

  // based on Levenshtein distance
  function similarity(s1, s2) {
    if (s1?.length > 0 && s2?.length > 0) {
      var longer = s1;
      var shorter = s2;
      if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
      }
      var longerLength = longer.length;
      if (longerLength == 0) {
        return 1.0;
      }
      return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
    } else return 0;
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  React.useEffect(() => {
    if (search?.length > 0) {
      // filter the docs state
      setSorting(
        documents
          .map((docs) => {
            return { document: docs, value: similarity(docs.title, search) };
          })
          .sort(function (a, b) {
            return b.value - a.value;
          })
      );
    }
  }, [search]);

  const searchComponent = () => (
    <Box
      component="form"
      sx={{
        flex: 1,
        minWidth: 300,
        "@media screen and (min-width: 600px)": {
          maxWidth: "600px",
        },
        "& > :not(style)": { m: 1, display: "flex" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        id="outlined-basic"
        label="Search Docs ..."
        variant="outlined"
      />
    </Box>
  );

  const sortComponent = () => (
    <Box
      component="div"
      sx={{
        "& > :not(style)": { m: 1, display: "flex" },
        "@media screen and (max-width: 600px)": {
          width: "0px !important",
          height: "0px !important",
          opacity: 0,
        },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{ alignItems: "center" }}>
        <p style={{ padding: "5px", fontFamily: "Google Sans", fontSize: "12px" }}>Sort By</p>
        <IconButton>
          <SortIcon />
        </IconButton>
      </div>
    </Box>
  );

  const docCard = ({ id, title, content, lastViewed }) => (
    <Card
      key={id}
      onClick={() => {
        handleCard(id);
      }}
      sx={{
        border: "1px solid white",
        minWidth: 200,
        maxWidth: 250,
        margin: "10px",
        transition: "0.2s ease all",
        cursor: "pointer",
        flex: 1,
        "&:hover": { border: "1px solid #012042", transition: "0.2s ease all" },
        "@media screen and (max-width: 500px)": {
          maxWidth: "500px",
        },
      }}
    >
      <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
        <Typography gutterBottom variant="h5" component="div">
          {title ?? "Untitled Document"}
        </Typography>
        <Typography component="span" variant="body2" color="text.secondary">
          <p
            style={{
              fontSize: "12px",
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 4,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {content ?? "Empty Doc"}
          </p>
        </Typography>
        <Typography
          variant="body4"
          color="text.secondary"
          component="span"
          style={{
            fontWeight: "bold",
            fontSize: 12,
            display: "flex",
            justifyContent: "Space-between",
            alignItems: "center",
          }}
        >
          <p>Viewed On {lastViewed ?? ""}</p>
          <div>
            <IconButton
              size="large"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleMenu({ menu: e, cardId: id })}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Typography>
      </CardContent>
      <Menu id="basic-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            navigate(`/page/${variables?.id}`);
          }}
        >
          <>
            <DriveFileRenameOutlineIcon
              style={{ width: "1.5em", height: "1.5em", marginRight: "5px" }}
            />
            Rename
          </>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            handleDelete();
          }}
        >
          <>
            <DeleteForeverIcon style={{ width: "1.5em", height: "1.5em", marginRight: "5px" }} />{" "}
            Remove
          </>
        </MenuItem>

        <Link to={`/page/${variables?.id}`} target="_blank" rel="noopener noreferrer">
          <MenuItem onClick={handleClose}>
            <>
              <OpenInNewIcon style={{ width: "1.5em", height: "1.5em", marginRight: "5px" }} /> Open
              in new Tab
            </>
          </MenuItem>
        </Link>
      </Menu>
    </Card>
  );

  const renderData = () => {
    if (sorting) {
      return sorting?.map((val) =>
        docCard({
          id: val.document.id,
          title: val.document.title,
          content: val.document.content,
          lastViewed: val.document.lastViewed,
        })
      );
    } else {
      return documents?.map((val) =>
        docCard({ id: val.id, title: val.title, content: val.content, lastViewed: val.lastViewed })
      );
    }
  };

  return (
    <div style={{ display: "flex", margin: "20px auto", maxWidth: 1200, overflow: "hidden" }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "",
          }}
        >
          <p
            style={{ padding: 10, fontFamily: "Google Sans", fontWeight: "bold", fontSize: "16px" }}
          >
            Recent documents
          </p>
          {searchComponent()}
          {/* {sortComponent()} */}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>{renderData()}</div>
      </div>
    </div>
  );
}

export default DocumentCard;
