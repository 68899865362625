import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import PersonIcon from "@mui/icons-material/Person";
// import SettingIcon from "@mui/icons-material/Settings";
import NotificationIcon from "@mui/icons-material/Notifications";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import RecommendIcon from "@mui/icons-material/Recommend";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { MenuOpen, MenuRounded } from "@mui/icons-material";
import { DeleteDocument } from "apollo/mutation/DocumentMutation";

function DashboardNavbar({ absolute, light, isMini, page }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [errors, setErrors] = useState({});
  const { deleteDocument, deleteLoading } = DeleteDocument(setErrors);
  const navigate = useNavigate();

  const route = useLocation().pathname.split("/").slice(1);

  // Delete document and return home
  const handleDeleteDocument = () => {
    const deletepending = deleteDocument({ variables: { id: parseInt(route[1]) } }).then(() => {
      if (Object.keys(errors).length < 1) {
        navigate("/");
      }
    });
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu({ target: event.currentTarget, icon: event.icon });
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => {
    switch (openMenu.icon) {
      case "delete":
        return (
          <Menu
            anchorEl={openMenu.target}
            anchorReference={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
          >
            <NotificationItem
              onClick={() => {
                handleDeleteDocument();
              }}
              icon={<WarningIcon />}
              title="Delete document Forever, this cannot be undone."
            />
          </Menu>
        );
      case "notifications":
        return (
          <Menu
            anchorEl={openMenu.target}
            anchorReference={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
          >
            <NotificationItem icon={<RecommendIcon />} title="No new notifications" />
          </Menu>
        );

      case "settings":
        return (
          <Menu
            anchorEl={openMenu.target}
            anchorReference={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
          >
            <NotificationItem icon={<WarningIcon />} title="Share this document" />
          </Menu>
        );
      default:
        return <></>;
    }
  };

  let title;
  if (page) {
    title = page;
  } else {
    title = route[route.length - 1] || "Dashboard";
  }

  const appbar = () => {
    return (
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Breadcrumbs icon={<HomeIcon />} title={title} route={route} light={light} />
          </MDBox>
          <MDBox>
            <h3 style={{ fontFamily: "sans-serif", fontWeight: "bold" }}>FinishEssay</h3>
          </MDBox>
          {isMini ? null : route[0] === "page" ? (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  style={{ outline: "none" }}
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  {miniSidenav ? <MenuRounded /> : <MenuOpen />}
                </IconButton>

                <IconButton
                  style={{ outline: "none" }}
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={(e) => handleOpenMenu({ ...e, icon: "delete" })}
                >
                  <DeleteIcon />
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          ) : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                <Link to="/profile">
                  <IconButton
                    style={{ outline: "none" }}
                    sx={navbarIconButton}
                    color="inherit"
                    size="small"
                    disableRipple
                  >
                    <PersonIcon />
                  </IconButton>
                </Link>

                <IconButton
                  style={{ outline: "none" }}
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={(e) => handleOpenMenu({ ...e, icon: "notifications" })}
                >
                  <NotificationIcon />
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  return page ? (
    <Box
      position={"sticky"}
      top="0px"
      zIndex={1100}
      sx={{
        transition: "0.2s ease all",
        "@media screen and (min-width: 1200px)": {
          marginLeft: "350px",
          transition: "0.2s ease all",
        },
      }}
    >
      {appbar()}
    </Box>
  ) : (
    appbar()
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
