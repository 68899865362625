import "./Page.css";
import React, { useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "../examples/Sidenav";
import brandWhite from "assets/images/logo-ct.png";

// Context Provider
import { useMaterialUIController, setMiniSidenav } from "../context";

// Material-UI
import { CircularProgress, IconButton, TextareaAutosize } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";
import { GetWrite } from "apollo/query/UserQuery";
import { GetDocument } from "apollo/query/DocumentQuery";
import { useParams } from "react-router-dom";
import { UpdateDocument } from "apollo/mutation/DocumentMutation";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Loader } from "components/loader/Loader";

function Page() {
  const [info, setInfo] = React.useState("");
  const [variables, setVariables] = React.useState({});
  const { getWrite, writeLoading } = GetWrite(setInfo);
  const [errors, setErrors] = React.useState({});
  const { updateDocument, updateLoading } = UpdateDocument(setErrors);

  const { id } = useParams();

  React.useEffect(() => {
    if (variables?.type) updateDocument({ variables });
  }, [variables]);

  // Get Doc based on id
  const { documentData, documentLoading } = GetDocument(id);

  // Only get the data if there is nothing in the docdata
  React.useEffect(() => {
    setVariables(documentData?.getDocument);
  }, [!documentData]);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleWrite = () => {
    getWrite({ variables });
  };

  const handleAccept = () => {
    setVariables({ ...variables, content: variables.content + info });
    setInfo("");
  };
  const handleReject = () => {
    setInfo("");
  };
  const handleRegenerate = () => {
    setInfo("Loading ...");
    handleWrite();
  };

  return (
    <>
      {documentLoading ? (
        <div style={{ display: "flex", justifyContent: "center", margin: "100px" }}>
          <Loader />
        </div>
      ) : (
        <>
          <DashboardNavbar page={variables?.title} />
          <Sidenav
            color={sidenavColor}
            brand={brandWhite}
            brandName=""
            routes={[]}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            vars={variables}
            setVars={setVariables}
            handleWrite={handleWrite}
            writeLoading={writeLoading}
          />
          <div style={{ backgroundColor: "white", display: "flex", flex: 1 }}>
            <DashboardLayout>
              <div style={{ color: "gray", maxWidth: "700px", width: "100%" }}>
                <div style={{ marginBottom: 40 }}>
                  <TextareaAutosize
                    className="writing-title text-font-style"
                    placeholder="Enter Your Title ..."
                    value={variables?.title ?? ""}
                    onChange={(e) => setVariables({ ...variables, title: e.target.value })}
                    rows="1"
                    style={{
                      overflow: "hidden",
                      overflowWrap: "break-word",
                      width: "100%",
                      border: "0px",
                    }}
                  />
                </div>
                <div>
                  <TextareaAutosize
                    className="writing-desc text-font-style"
                    placeholder="Write here ..."
                    onChange={(e) => setVariables({ ...variables, content: e.target.value })}
                    value={variables?.content ?? ""}
                    rows="1"
                    style={{
                      overflow: "hidden",
                      overflowWrap: "break-word",

                      width: "100%",
                      border: "0px",
                    }}
                  />
                </div>
                {info && (
                  <div
                    className={info ? "text-faded-in" : "text-faded-out"}
                    style={{ backgroundColor: "#d5d9ff", padding: 10, borderRadius: 10 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        backgroundColor: "white",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div onClick={handleAccept} className="text-button">
                        Accept
                      </div>
                      <div onClick={handleReject} className="text-button">
                        Reject
                      </div>
                      <div onClick={handleRegenerate} className="text-button">
                        {writeLoading ? "Loading..." : "Regenerate"}
                      </div>
                    </div>
                    <p>{info}</p>
                  </div>
                )}

                <div style={{ marginBottom: "350px" }}></div>
              </div>
            </DashboardLayout>
          </div>
        </>
      )}
    </>
  );
}

export default Page;
