import { CircularProgress } from "@mui/material";
import { CheckPayment } from "apollo/query/UserQuery";
import React from "react";

function HandlePayment() {
  const [error, setErrors] = React.useState({});
  const { checkPayment, paymentLoading } = CheckPayment(setErrors);

  React.useEffect(() => {
    // Check if user has paid for subscription
    checkPayment();
  }, []);

  return (
    <div style={{ textAlign: "center", margin: 40 }}>
      <CircularProgress />
    </div>
  );
}

export default HandlePayment;
