import React, { createContext, useReducer, useContext } from "react";
import { RefreshUser, SingleRefreshUser } from "../apollo/mutation/UserMutation";
import jwtDecode from "jwt-decode";

const AuthDispatchContext = createContext();
const AuthStateContext = createContext();

let decodedToken;
let user;
let token = localStorage.getItem("token");
const refresh = localStorage.getItem("refresh");

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refresh", action.payload.refresh);
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT": {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      return {
        ...state,
        user: null,
      };
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const RefreshToken = () => {
  const [tokens, setTokens] = React.useState({});
  const [_token, setToken] = React.useState({});
  const { refreshUser, __ } = RefreshUser(setTokens);
  const { singleRefreshUser, _ } = SingleRefreshUser(setToken);

  if (token) {
    decodedToken = jwtDecode(token);
    const expiresAt = new Date(decodedToken.exp * 1000); // ms as default
    if (new Date() > expiresAt) {
      localStorage.removeItem("token");
      token = null;
    } else {
      user = decodedToken;
    }
  }

  React.useEffect(() => {
    if (Object.keys(tokens).length !== 0) {
      localStorage.removeItem("refresh");
      localStorage.setItem("token", tokens.token);
      localStorage.setItem("refresh", tokens.refresh);
      user = jwtDecode(tokens.token);
    }
  }, [tokens]);

  React.useEffect(() => {
    if (refresh && !token) {
      refreshUser({ variables: { refreshToken: refresh } });
    }

    if (token) {
      const expiresAt = new Date(decodedToken.exp * 1000); // ms as default
      const expired = (expiresAt - new Date()) / 60000 < 15; // 60000 -> 1000 * 60 (minutes)
      if (expired) {
        singleRefreshUser({ variables: { token } });
      }
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(_token).length !== 0) {
      localStorage.removeItem("token");
      localStorage.setItem("token", _token.token);
      user = jwtDecode(_token.token);
    }
  }, [_token]);
};

export function AuthProvider({ children }) {
  RefreshToken();
  const [state, dispatch] = useReducer(authReducer, { user });

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
