import { Tooltip } from "@mui/material";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import gpt3Logo from "../../logo.svg";
import "./footer.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div id="Footer" className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Become a better writer today!</h1>
      </div>

      <div
        onClick={() => {
          navigate("/dashboard");
        }}
        className="gpt3__footer-btn"
      >
        <p>sign up</p>
      </div>

      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <h1 style={{ color: "white" }}>FinishEssay</h1>
          {/* <img src={gpt3Logo} alt="gpt3_logo" /> */}
          <p>
            FinishEssay, <br /> All Rights Reserved
          </p>
        </div>
        <Tooltip placement="top-start" title="Comming soon">
          <div className="gpt3__footer-links_div">
            <h4>🔗 Links</h4>
            <p>Overons</p>
            <p>Social Media</p>
            <p>Counters</p>
            <p>Contact</p>
          </div>
        </Tooltip>
        <Tooltip placement="top-start" title="Comming soon">
          <div className="gpt3__footer-links_div">
            <h4>🏢 Company</h4>
            <p>Terms & Conditions </p>
            <p>Privacy Policy</p>
            <p>Contact</p>
          </div>
        </Tooltip>
        <div className="gpt3__footer-links_div">
          <h4>
            <span className="wave-emoji" role="img" aria-label="waving hand">
              👋
            </span>{" "}
            Get in touch
          </h4>
          <p>FinishEssay Email</p>
          {/* <p>085-132567</p> */}
          <p>info@finishessay.com</p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>@2022 FinishEssay. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
