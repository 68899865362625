import React, { useRef } from "react";
import { styled } from "@mui/system";
import { useAuthState } from "context/Auth";
import smoothscroll from "smoothscroll-polyfill";
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StripePayment } from "apollo/mutation/UserMutation";

smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;

const scrollToRef = (ref) => {
  setTimeout(function () {
    window.scroll({ top: ref.current.offsetTop - 100, left: 0, behavior: "smooth" });
  }, 600);
};
// General scroll to element function

function PaymentCards() {
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const executeScroll2 = () => scrollToRef(myRef2);

  const { stripePayment, stripeLoading } = StripePayment();

  const { user } = useAuthState();
  const navigate = useNavigate();

  const onHandlePayment = (type) => {
    if (!user) {
      navigate(`/authentication/sign-up`, { replace: true });
    } else {
      switch (type) {
        case "monthly":
          stripePayment({});
          break;
        case "credit":
          break;
        default:
          break;
      }
    }
  };

  const CardWrapper = styled("div")({
    transition: "0.5s all ease",
    "& > div": {
      maxHeight: 200,
      transition: "max-height 0.5s ease-out",
      overflow: "hidden",
    },
    "& > div > div > .payment-info > div": {
      transition: "0.5s all ease",
      opacity: 0,
      color: "gray",
    },
    "& > div > div > .payment-info": {
      transition: "0.5s all ease",
      opacity: 0,
      borderTop: "1px solid white",
    },
    "&:hover": {
      "& > div > div > .payment-info": {
        transition: "0.5s all ease",
        opacity: 1,
        borderTop: "1px solid rgba(43, 41, 46, 0.08)",
      },
      marginTop: "15px",
      "& > div": {
        maxHeight: 800,
        transition: "all 0.5s ease-out",
        overflow: "hidden",
        transitionDelay: "0.5s",
        border: "1px solid rgb(102, 117, 255)",
      },
      "& > div > div > .payment-info > div": {
        transition: "0.5s all ease",
        opacity: 1,
        color: "rgb(102, 117, 255)",
      },
    },
  });

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", textAlign: "center" }}
    >
      <CardWrapper>
        <Card
          onClick={executeScroll}
          ref={myRef}
          sx={{ minWidth: 200, maxWidth: 330, margin: "10px", flex: 1 }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Typography gutterBottom variant="h5" component="div">
              Quaterly Plan
            </Typography>
            <Typography variant="body3" color="text.secondary">
              Anytime Cancel, Billed Quaterly
            </Typography>
            <Typography style={{ margin: 10 }} variant="h3" color="text.secondary">
              <span style={{ fontSize: "16px" }}>$</span>25.00
            </Typography>
            <Typography style={{ margin: 10 }} variant="body4" color="text.secondary">
              <span style={{ fontSize: "16px" }}>Quaterly Plan</span>
            </Typography>
            <div
              className="payment-info"
              style={{
                marginTop: "20px",
                paddingTop: "15px",
                paddingBottom: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ height: "20px", flexShrink: 0 }}
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="StyledIconBase-ea9ulj-0 fHrGFt sc-cOLXoO eMRKWs"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                  </svg>
                  <p
                    style={{
                      fontFamily: "ProximaNova, Arial, Helvetica, sans-serif",
                      color: "rgb(29, 29, 29)",
                      fontSize: "14px",
                      lineHeight: "25px",
                      margin: "10px",
                    }}
                  >
                    Powerful commands to help you write faster
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ height: "20px", flexShrink: 0 }}
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="StyledIconBase-ea9ulj-0 fHrGFt sc-cOLXoO eMRKWs"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                  </svg>
                  <p
                    style={{
                      fontFamily: "ProximaNova, Arial, Helvetica, sans-serif",
                      color: "rgb(29, 29, 29)",
                      fontSize: "14px",
                      lineHeight: "25px",
                      margin: "10px",
                    }}
                  >
                    Improve your writing and make writer’s block a thing of the past
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ height: "20px", flexShrink: 0 }}
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="StyledIconBase-ea9ulj-0 fHrGFt sc-cOLXoO eMRKWs"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                  </svg>
                  <p
                    style={{
                      fontFamily: "ProximaNova, Arial, Helvetica, sans-serif",
                      color: "rgb(29, 29, 29)",
                      fontSize: "14px",
                      lineHeight: "25px",
                      margin: "10px",
                    }}
                  >
                    Continuous updates - our AI only gets better with time
                  </p>
                </div>
              </div>
            </div>
            <CardActions style={{ justifyContent: "center" }}>
              <>
                <Button onClick={() => onHandlePayment("monthly")} size="small">
                  Start
                </Button>
                <Button size="small">More</Button>
              </>
            </CardActions>
          </CardContent>
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card
          ref={myRef2}
          onClick={executeScroll2}
          sx={{ minWidth: 200, maxWidth: 330, margin: "10px", flex: 1 }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Typography gutterBottom variant="h5" component="div">
              Credit Based
            </Typography>
            <Typography variant="body3" color="text.secondary">
              Pay for what you use
            </Typography>
            <Typography style={{ margin: 10 }} variant="h3" color="text.secondary">
              <span style={{ fontSize: "16px" }}>$</span>5{" "}
              <span style={{ fontSize: "20px" }}>Minimum</span>
            </Typography>
            <Typography style={{ margin: 10 }} variant="body4" color="text.secondary">
              <span style={{ fontSize: "16px" }}>Credit Based</span>
            </Typography>
            <div
              className="payment-info"
              style={{
                marginTop: "20px",
                paddingTop: "15px",
                paddingBottom: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ height: "20px", flexShrink: 0 }}
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="StyledIconBase-ea9ulj-0 fHrGFt sc-cOLXoO eMRKWs"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                  </svg>
                  <p
                    style={{
                      fontFamily: "ProximaNova, Arial, Helvetica, sans-serif",
                      color: "rgb(29, 29, 29)",
                      fontSize: "14px",
                      lineHeight: "25px",
                      margin: "10px",
                    }}
                  >
                    Powerful commands to help you write faster
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ height: "20px", flexShrink: 0 }}
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="StyledIconBase-ea9ulj-0 fHrGFt sc-cOLXoO eMRKWs"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                  </svg>
                  <p
                    style={{
                      fontFamily: "ProximaNova, Arial, Helvetica, sans-serif",
                      color: "rgb(29, 29, 29)",
                      fontSize: "14px",
                      lineHeight: "25px",
                      margin: "10px",
                    }}
                  >
                    Improve your writing and make writer’s block a thing of the past
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <svg
                    style={{ height: "20px", flexShrink: 0 }}
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="StyledIconBase-ea9ulj-0 fHrGFt sc-cOLXoO eMRKWs"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                  </svg>
                  <p
                    style={{
                      fontFamily: "ProximaNova, Arial, Helvetica, sans-serif",
                      color: "rgb(29, 29, 29)",
                      fontSize: "14px",
                      lineHeight: "25px",
                      margin: "10px",
                    }}
                  >
                    Continuous updates - our AI only gets better with time
                  </p>
                </div>
              </div>
            </div>
            <CardActions style={{ justifyContent: "center" }}>
              <>
              <p style={{color: "#81afdd", fontWeight: "bold"}}>Coming Soon...</p>
                {/* <Button onClick={() => onHandlePayment("credit")} size="small">
                  Start
                </Button>
                <Button size="small">More</Button> */}
              </>
            </CardActions>
          </CardContent>
        </Card>
      </CardWrapper>
    </div>
  );
}

export default PaymentCards;
