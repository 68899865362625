import { Box } from "@mui/system";
import { useAuthState } from "context/Auth";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { RemoveSubscription } from "apollo/mutation/UserMutation";
import { GetInvoices } from "apollo/query/UserQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  minWidth: 300,
  bgcolor: "background.paper",
  border: "1px solid #012042",
  boxShadow: 24,
  p: 4,
};

export const handleSignOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");

  window.location.reload();
};

function Profile() {
  const navigate = useNavigate();
  const { user } = useAuthState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [errors, setErrors] = React.useState({});
  const [info, setInfo] = React.useState("");
  const { removeSubscription, removeLoading } = RemoveSubscription(setErrors);
  const { getInvoices, invoicesLoading } = GetInvoices(setInfo);

  const handleUnsub = () => {
    removeSubscription();
  };

  const handleInvoices = () => {
    getInvoices();
  };

  React.useEffect(() => {
    if (info !== "") window.location.href = info;
  }, [info]);

  React.useEffect(() => {
    if (!user) {
      navigate("/", { replace: true });
    }
  }, []);

  return user ? (
    <>
      <DashboardNavbar />
      <div style={{ fontFamily: "sans-serif", maxWidth: 800, margin: "auto" }}>
        <h1 style={{ padding: "20px" }}>Your Account</h1>
        <Box sx={{ padding: "25px 20px", margin: "0px 20px", display: "flex" }}>
          <p style={{ fontWeight: "bold" }}>Email: &nbsp;</p>
          <p> {user?.userId}</p>
        </Box>

        <h3 style={{ padding: "20px" }}>Your Plan</h3>
        <Box
          onClick={() => {
            if (user?.subscriptionType === "subscription") {
              handleOpen();
            } else {
              navigate("/pricing", { replace: true });
            }
          }}
          sx={{
            backgroundColor: "#012042",
            border: "1px solid white",
            padding: "25px 20px",
            margin: "0px 20px",
            transition: "0.2s ease all",
            cursor: "pointer",
            borderRadius: 3,
            color: "white",
            flex: 1,
            "&:hover": {
              border: "1px solid #012042",
              transition: "0.2s ease all",
              backgroundColor: "#76a2e3",
            },
          }}
        >
          <p style={{ color: "white", fontFamily: "sans-serif", fontWeight: "bold" }}>
            {user?.subscriptionType === "subscription" ? "Premium" : "Limited"}
          </p>
        </Box>
        <h3 style={{ padding: "20px" }}>Billing</h3>
        <Box
          onClick={() => {
            handleInvoices();
          }}
          sx={{
            backgroundColor: "#012042",
            border: "1px solid white",
            padding: "25px 20px",
            margin: "0px 20px",
            transition: "0.2s ease all",
            cursor: "pointer",
            borderRadius: 3,
            color: "white",
            flex: 1,
            "&:hover": {
              border: "1px solid #012042",
              transition: "0.2s ease all",
              backgroundColor: "#76a2e3",
            },
          }}
        >
          <p style={{ color: "white", fontFamily: "sans-serif", fontWeight: "bold" }}>
            View Invoices
          </p>
        </Box>
        <h3 style={{ padding: "20px" }}>Sign Out</h3>
        <Box
          onClick={() => handleSignOut()}
          sx={{
            backgroundColor: "#ff9a9a",
            border: "1px solid white",
            padding: "25px 20px",
            margin: "0px 20px",
            transition: "0.2s ease all",
            cursor: "pointer",
            borderRadius: 3,
            color: "white",
            flex: 1,
            "&:hover": {
              border: "1px solid #012042",
              transition: "0.2s ease all",
              backgroundColor: "#ff4d4d",
            },
          }}
        >
          <p style={{ color: "white", fontFamily: "sans-serif", fontWeight: "bold" }}>Sign Out</p>
        </Box>
      </div>
      <Footer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography color="#ff7878" id="modal-modal-title" variant="h6" component="h2">
            Unsubscribe
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please note this takes in effect immediately and you will not have access to your
            documents
          </Typography>
          <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
            <Button onClick={handleUnsub} style={{ backgroundColor: "#012042", color: "white" }}>
              Unsubscribe
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  ) : (
    <></>
  );
}

export default Profile;
