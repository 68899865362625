import "./Payment.css";

import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import Footer from "examples/Footer";
import { useAuthState } from "context/Auth";
import { styled } from "@mui/material/styles";
import Description from "components/payment/Description";
import PaymentCards from "components/payment/PaymentCards";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";

const Root = styled("span")(({ theme }) => ({
  transition: "0.3s all ease",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  [theme.breakpoints.up("lg")]: {
    padding: "20px",
    fontSize: "60px",
    lineHeight: "65px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "40px",
    lineHeight: "45px",
  },
}));

const Root2 = styled("span")(({ theme }) => ({
  transition: "0.3s all ease",
  fontFamily: "sans-serif",
  padding: "20px",
  fontSize: "18px",
}));

const Root3 = styled("div")(({ theme }) => ({
  transition: "0.3s all ease",
  backgroundColor: "#def3ff",
  fontFamily: "sans-serif",
  [theme.breakpoints.up("lg")]: {
    height: "800px",
  },
  [theme.breakpoints.down("lg")]: {
    "& > .box-footer": {
      visibility: "hidden",
    },
  },
}));

const Root4 = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "700px",
  right: "50px",
  cursor: "pointer",
  animation: "bounce 5s infinite",
  "@keyframes bounce": {
    "0%, 15%, 25%, 80%, 100%": {
      transform: "translateY(0)",
    },
    "10%": {
      transform: "translateY(-30px)",
    },
    "20%": {
      transform: "translateY(-15px)",
    },
  },
  [theme.breakpoints.down("lg")]: {
    "& > svg": {
      visibility: "hidden",
    },
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Payment() {
  const myRef = useRef(null);
  const { height, width } = useWindowDimensions();
  const executeScroll = () => myRef.current.scrollIntoView();
  const { user } = useAuthState();
  const navigate = useNavigate();

  const [type, setType] = React.useState("");

  // Type updated
  React.useEffect(() => {
    // console.log(type);
  }, [type]);

  // Type updated
  React.useEffect(() => {
    if (user?.subscriptionType === "subscription") navigate("/");
  }, []);

  return (
    <>
      <Root3 className="dash-root gradient__bg">
        {width > 800 && (
          <Root4 onClick={executeScroll}>
            <ArrowDropDownIcon
              style={{
                width: "3em",
                height: "3em",
                backgroundColor: "#0095ff",
                borderRadius: "10px",
              }}
            />
          </Root4>
        )}
        <DashboardNavbar />
        <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
          <Root className="gradient__text">Unlimited Potential.</Root>
          <Root2 className="gradient__text">
            write exceptional, ellegant paragraphs from a simple click of a button
          </Root2>
        </div>
        <PaymentCards setType={setType} />

        <Box
          className="box-footer"
          sx={{
            "@media screen and (min-width: 500px)": {
              backgroundColor: "#ffffff",
              position: "fixed",
              bottom: "0px",
            },
          }}
          style={{
            width: "100%",
            display: "flex",
            padding: "10px",
          }}
        >
          <Footer />
        </Box>
      </Root3>
      <div ref={myRef}>
        <Description />
      </div>
    </>
  );
}

export default Payment;
