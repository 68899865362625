import { gql, useLazyQuery } from "@apollo/client";
import { useDocumentDispatch } from "context/Documents";
import React from "react";

const GET_DOCUMENTS = gql`
  query getDocuments {
    getDocuments {
      id
      type
      title
      content
      isPublic
      updatedAt
      shareLink
      createdAt
      background
      lastEdited
      lastViewed
    }
  }
`;

const GET_DOCUMENT = gql`
  query getDocument($getDocumentId: Int!) {
    getDocument(id: $getDocumentId) {
      title
      content
      type
      isPublic
      updatedAt
      shareLink
      createdAt
      background
      lastEdited
      lastViewed
      userId
      id
    }
  }
`;

export const GetDocuments = (setErrors) => {
  const dispatch = useDocumentDispatch();
  const [getDocuments, { loading: documentsLoading, data: documentsData }] = useLazyQuery(
    GET_DOCUMENTS,
    {
      onCompleted: (data) => {
        dispatch({ type: "DOCUMENTS", payload: data.getDocuments });
      },
    }
  );

  React.useEffect(() => {
    getDocuments();
  }, []);

  return { documentsData, documentsLoading };
};

export const GetDocument = (id, setErrors) => {
  const dispatch = useDocumentDispatch();
  const [getDocument, { loading: documentLoading, data: documentData }] =
    useLazyQuery(GET_DOCUMENT);

  React.useEffect(() => {
    getDocument({
      variables: {
        getDocumentId: parseInt(id),
      },
    });
  }, []);

  return { documentData, documentLoading };
};
