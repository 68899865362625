import React from "react";
import { Link } from "react-router-dom";
import "./cta.css";

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Sign Up now to Get Started</p>
      <h3>Register Today & start exploring the endless possibilities.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <Link to="/authentication/sign-up" type="button">
        <button>Get Started</button>
      </Link>
    </div>
  </div>
);

export default CTA;
