import React from "react";

import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
} from "../../containers";
import { CTA, Brand, Navbar } from "../../components";

import "./Landpage.css";

const Landpage = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    {/* <Brand /> */}
    <Possibility />
    <WhatGPT3 />
    <Features />
    <CTA />
    {/* <Blog /> */}
    <Footer />
  </div>
);

export default Landpage;
