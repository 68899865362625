import "./Dashboard.css";

import React from "react";
import Footer from "examples/Footer";
import { Loader } from "components/loader/Loader";
import { useDocumentState } from "context/Documents";
import { GetDocuments } from "apollo/query/DocumentQuery";
import StartCards from "components/document/StartCard";
import DocumentCards from "components/document/DocumentCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Dashboard() {
  const { documents } = useDocumentState() ?? {};

  let docsLoading;
  if (!documents) {
    // Get Doc data and push into documentCards
    const { documentsLoading } = GetDocuments();
    docsLoading = documentsLoading;
  }

  return (
    <>
      {docsLoading ? (
        <div style={{ display: "flex", justifyContent: "center", margin: "100px" }}>
          <Loader />
        </div>
      ) : (
        <div className="dash-root">
          <DashboardNavbar />
          <div className="dash-container">
            <div className="dash-header">
              <StartCards />
            </div>
            <DocumentCards documents={documents} />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Dashboard;
