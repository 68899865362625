import "./index.css";

import React from "react";
// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "../../layouts/authentication/components/BasicLayout";

import login from "assets/login.png";

// GraphQL Apollo Functions
// eslint-disable-next-line import/named
import { useAuthState } from "context/Auth";
import { RegisterUser } from "../../apollo/mutation/UserMutation";

// Images

function SignUp(props) {
  const { id } = useParams();

  const [errors, setErrors] = React.useState({});
  const [variables, setVariables] = React.useState({
    email: id,
    fullName: "",
    password: "",
    termscondition: false,
  });

  let { registerUser, loading } = RegisterUser(setErrors);

  const submitRegisterForm = (e) => {
    e.preventDefault();
    registerUser({ variables });
  };

  const setTerms = (value) => {
    setVariables({ ...variables, termscondition: value });
  };

  // Check if user is logged in already, and redirect back to dashboard
  const { user } = useAuthState();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  return (
    <BasicLayout image={login}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox onSubmit={submitRegisterForm} component="form" role="form">
            <MDBox
              onChange={(e) => setVariables({ ...variables, fullName: e.target.value })}
              mb={2}
            >
              <MDInput
                className={errors.name && "text-red"}
                type="text"
                label={errors.name ?? "Name"}
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox onChange={(e) => setVariables({ ...variables, email: e.target.value })} mb={2}>
              <MDInput
                className={errors.email && "text-red"}
                type="email"
                label={errors.email ?? "Email"}
                variant="standard"
                value={variables?.email}
                fullWidth
              />
            </MDBox>
            <MDBox
              onChange={(e) => setVariables({ ...variables, password: e.target.value })}
              mb={2}
            >
              <MDInput
                className={errors.password && "text-red"}
                type="password"
                label={errors.password ?? "Password"}
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onChange={(e) => setTerms(e.target.checked)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color={errors.termscondition ? "error" : "text"}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color={errors.termscondition ? "error" : "info"}
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="input" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
