import { gql, useMutation } from "@apollo/client";
import { useAuthDispatch } from "context/Auth";
import { useNavigate } from "react-router-dom";

// import { NetworkIssues } from "../../components/allerrors/Issues";

// Apollo User Mutations

const RESET_USER = gql`
  mutation ResetPassword($token: String!, $email: String!, $password: String!) {
    resetPassword(token: $token, email: $email, password: $password) {
      fullName
    }
  }
`;

const REFRESH_USER = gql`
  mutation refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      token
      refresh
    }
  }
`;

const SINGLE_REFRESH_USER = gql`
  mutation singleRefresh($token: String!) {
    singleRefresh(token: $token) {
      token
    }
  }
`;

const STRIPE_PAYMENT = gql`
  mutation stripePayment {
    stripePayment {
      url
    }
  }
`;

const UNSUBSCRIBE = gql`
  mutation removeSubscription {
    removeSubscription {
      token
      refresh
    }
  }
`;

const REGISTER_USER = gql`
  mutation register(
    $email: String!
    $fullName: String!
    $password: String!
    $termscondition: Boolean!
  ) {
    register(
      email: $email
      fullName: $fullName
      password: $password
      termscondition: $termscondition
    ) {
      email
      fullName
      createdAt
    }
  }
`;

export const RegisterUser = (setErrors) => {
  const navigate = useNavigate();
  const [registerUser, { loading }] = useMutation(REGISTER_USER, {
    // eslint-disable-next-line no-unused-vars
    update: (_, __) => {
      navigate("/authentication/sign-in", { replace: true });
    },
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
  });

  return { registerUser, loading };
};

export const RefreshUser = (setTokens) => {
  const [refreshUser, { loading }] = useMutation(REFRESH_USER, {
    update: (proxy, mutationResult) => {
      const valueReturned = mutationResult.data.refresh;
      setTokens(valueReturned);
    },
  });
  return { refreshUser, loading };
};

export const SingleRefreshUser = (setToken) => {
  const [singleRefreshUser, { loading }] = useMutation(SINGLE_REFRESH_USER, {
    update: (proxy, mutationResult) => {
      const valueReturned = mutationResult.data.singleRefresh;
      setToken(valueReturned);
    },
  });
  return { singleRefreshUser, loading };
};

export const StripePayment = (email) => {
  const [stripePayment, { loading: stripeLoading }] = useMutation(STRIPE_PAYMENT, {
    update: (proxy, mutationResult) => {
      window.location.href = mutationResult?.data?.stripePayment?.url;
    },
  });
  return { stripePayment, stripeLoading };
};

export const RemoveSubscription = (setErrors) => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const [removeSubscription, { loading: removeLoading }] = useMutation(UNSUBSCRIBE, {
    onCompleted: (data) => {
      dispatch({ type: "LOGIN", payload: data?.removeSubscription });
      navigate("/");
    },
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
  });
  return { removeSubscription, removeLoading };
};

export const ResetPassword = (setErrors) => {
  const navigate = useNavigate();
  const [resetPassword, { loading: rpLoading }] = useMutation(RESET_USER, {
    // eslint-disable-next-line no-unused-vars
    update: (_, __) => {
      // eslint-disable-next-line no-alert
      alert("Password was successfully changed.");
      navigate("/authentication/sign-in", { replace: true });
    },
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
  });
  return { resetPassword, rpLoading };
};
