import { DocumentProvider } from "context/Documents";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ApolloProvider from "./apollo/ApolloProvider";

import App from "./App";
import { MaterialUIControllerProvider } from "./context";
import { AuthProvider } from "./context/Auth";
import "./index.css";

ReactDOM.render(
  <ApolloProvider>
    <AuthProvider>
      <DocumentProvider>
        <MaterialUIControllerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MaterialUIControllerProvider>
      </DocumentProvider>
    </AuthProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
