import { gql, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch } from "../../context/Auth";

const LOGIN_USER = gql`
  query login($email: String!, $password: String!, $remember: Boolean!) {
    login(email: $email, password: $password, remember: $remember) {
      email
      token
      refresh
      subscriptionType
    }
  }
`;

const CHECK_PAYMENT = gql`
  query checkPayment {
    checkPayment {
      email
      token
      refresh
      subscriptionType
    }
  }
`;

const GET_RESET = gql`
  query GetReset($email: String!) {
    getReset(email: $email) {
      info
    }
  }
`;

const GET_WRITE = gql`
  query Query(
    $type: String!
    $outputLength: Int
    $content: String
    $title: String
    $background: String
  ) {
    getWrite(
      type: $type
      output_length: $outputLength
      content: $content
      title: $title
      background: $background
    ) {
      info
    }
  }
`;

const GET_INVOICES = gql`
  query getInvoices {
    getInvoices {
      info
    }
  }
`;

export const LoginUser = (setErrors) => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const [loginUser, { loading }] = useLazyQuery(LOGIN_USER, {
    fetchPolicy: "network-only",
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
    onCompleted: (data) => {
      dispatch({ type: "LOGIN", payload: data.login });
      navigate("/dashboard");
    },
  });

  return { loginUser, loading };
};

export const CheckPayment = (setErrors) => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const [checkPayment, { loading: paymentLoading }] = useLazyQuery(CHECK_PAYMENT, {
    fetchPolicy: "network-only",
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setErrors(err.graphQLErrors[0].extensions.errors);
      } else {
        setErrors(err.networkError);
        // NetworkIssues();
      }
    },
    onCompleted: (data) => {
      dispatch({ type: "LOGIN", payload: data.checkPayment });
      navigate("/dashboard");
    },
  });

  return { checkPayment, paymentLoading };
};

export const GetInvoices = (setInfo) => {
  const [getInvoices, { loading: invoicesLoading }] = useLazyQuery(GET_INVOICES, {
    fetchPolicy: "network-only",
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setInfo(err.graphQLErrors[0].extensions.errors);
      } else {
        setInfo(err.networkError);
        // NetworkIssues();
      }
    },
    onCompleted: (data) => {
      setInfo(data.getInvoices.info);
    },
  });

  return { getInvoices, invoicesLoading };
};

export const GetReset = (setInfo) => {
  const [getReset, { loading: resetLoading }] = useLazyQuery(GET_RESET, {
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setInfo(err.graphQLErrors[0].extensions.errors);
      } else {
        setInfo(err.networkError);
        // NetworkIssues();
      }
    },
    onCompleted: (data) => {
      setInfo(data.getReset.info);
    },
  });

  return { getReset, resetLoading };
};

export const GetWrite = (setInfo) => {
  const [getWrite, { loading: writeLoading }] = useLazyQuery(GET_WRITE, {
    onError: (err) => {
      if (err.graphQLErrors[0] !== undefined) {
        setInfo(err.graphQLErrors[0].extensions.errors);
      } else {
        setInfo(err.networkError);
        // NetworkIssues();
      }
    },
    onCompleted: (data) => {
      setInfo(data.getWrite.info);
    },
  });

  return { getWrite, writeLoading };
};
