import "./index.css";
import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "../../layouts/authentication/components/BasicLayout";

// Images
import login from "assets/login.png";

import { useNavigate, useParams } from "react-router-dom";
import { GetReset } from "apollo/query/UserQuery";
import { ResetPassword } from "apollo/mutation/UserMutation";
import { useAuthState } from "../../context/Auth";

function ResetPass() {
  const { id } = useParams();
  const [info, setInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [variables, setVariables] = React.useState({ email: "" });
  const [variablesReset, setVariablesReset] = React.useState({
    email: "",
    password: "",
    token: id,
  });
  const { getReset, resetLoading } = GetReset(setInfo);
  const { resetPassword, rpLoading } = ResetPassword(setErrors);

  // $token: String!, $email: String!, $password: String

  const handleReset = (e) => {
    e.preventDefault();

    getReset({ variables });
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();

    resetPassword({ variables: variablesReset });
  };

  let forms;
  if (Object.keys(info).length > 0) {
    // forms = JSON.parse(info)?.accepted !== null ?? false;
    try {
      forms = JSON.parse(info).accepted !== null;
    } catch (e) {
      forms = false;
    }
  } else {
    forms = false;
  }

  // Check if user is logged in already, and redirect back to dashboard
  const { user } = useAuthState();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  return (
    <BasicLayout image={login}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          {id ? (
            <>
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Reset Password
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Enter the details below to update your password
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Reset Password
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                You will receive an e-mail in maximum 60 seconds
              </MDTypography>
            </>
          )}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {Object.keys(info).length > 0 && (
            <MDBox textAlign="center" pt={0} pb={1} px={3}>
              <MDTypography display="block" variant="button" color="black" my={1}>
                {info?.email}
                {forms && `Successfully emailed to: ${JSON.parse(info)?.accepted}`}
              </MDTypography>
            </MDBox>
          )}
          {!forms && !id && (
            <MDBox onSubmit={handleReset} component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  onChange={(e) => setVariables({ ...variables, email: e.target.value })}
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton type="input" variant="gradient" color="info" fullWidth>
                  {resetLoading ? <CircularProgress color="warning" /> : "reset"}
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {id && (
            <MDBox onSubmit={handlePasswordReset} component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  onChange={(e) => setVariablesReset({ ...variablesReset, email: e.target.value })}
                  type="email"
                  label={errors.email ?? "Email"}
                  className={errors.email && "text-red"}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  onChange={(e) =>
                    setVariablesReset({ ...variablesReset, password: e.target.value })
                  }
                  type="password"
                  label="New Password"
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton type="input" variant="gradient" color="info" fullWidth>
                  {rpLoading ? <CircularProgress color="warning" /> : "reset"}
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPass;
