// eslint-disable-next-line no-undef
let currentUrl = globalThis.location?.href;
let exportLocations = {};

if (String(currentUrl).includes("localhost")) {
  exportLocations = {
    SERVER_ADDRESS: "http://localhost:3000",
    SERVER_API_ADDRESS: "http://localhost:4000",
    SOCKET_API_ADDRESS: "localhost:4000",
  };
} else {
  exportLocations = {
    SERVER_ADDRESS: "https://finishessay.com",
    SERVER_API_ADDRESS: "https://api.finishessay.com",
    SOCKET_API_ADDRESS: "api.finishessay.com",
  };
}

module.exports = exportLocations;
