import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
// import logo from '../../logo.svg';
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">FinishEssay</div>
        <div className="gpt3__navbar-links_container">
          <p>
            <a href="#home">Home</a>
          </p>
          <p>
            <a href="/pricing">Pricing</a>
          </p>
          <p>
            <a href="#possibility">Support</a>
          </p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <div
          onClick={() => {
            navigate("/dashboard", { replace: true });
          }}
        >
          <p>Sign in</p>
        </div>
        <button
          onClick={() => {
            navigate("/authentication/sign-up", { replace: true });
          }}
          type="button"
        >
          Sign up
        </button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <a href="#home">Home</a>
              </p>
              <p>
                <a href="#possibility">Possibilities</a>
              </p>
              <p>
                <a href="#wgpt3">Overview</a>
              </p>
              <p>
                <a href="#features">The Future</a>
              </p>
              <p>
                <a href="#Footer">More Info</a>
              </p>
            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              <p onClick={() => navigate("/authentication/sign-up", { replace: true })}>Sign in</p>
              <button onClick={() => navigate("/authentication/sign-up", { replace: true })} type="button">Sign up</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
