import { Box } from "@mui/material";
import React from "react";

const Description = () => {
  return (
    <div
      style={{
        textAlign: "left",
        marginBottom: "200px",
        fontFamily: "sans-serif",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          maxWidth: "1000px",
          flex: 1,
          flexDirection: "column",
          padding: "0px 10px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            padding: "40px",
            fontSize: "35px",
          }}
        >
          Most asked questions
        </h2>
        <h3 style={{ fontSize: "20[x", lineHeight: "50px" }}>What is it and how does it work?</h3>
        <p>
          FinishEssay is a AI Based writing tool for anyone that wants an edge on their writing. It
          is able to deliver satisfying work with a fraction of the time and effort it takes. <br />
          <br />
          The system is designed to be minimalist, highly functional and intelligent. The system
          works by reading through your essay, identifying important points and starting with the
          most meaningful parts. <br />
          <br />
          It then uses <span style={{ color: "#003f85", fontWeight: "bold" }}> GPT-3</span> to link
          the importance of these important parts and points with different types of sentences,
          which provide the structure for your essay. The system then fills in missing words and
          sentences based on your input. FinishEssay will use a powerful algorithm to find the
          information you need, making it easy to understand and use - even for those who may never
          have written an essay before.
        </p>
        {/* <h3 style={{ fontSize: "20[x", lineHeight: "50px" }}>
          How much can I write with the pay as you go plan?
        </h3>
        <p>You can pay as you go</p> */}
        <br />
        <h3 style={{ fontSize: "20[x", lineHeight: "50px" }}>Is the AI output original?</h3>
        <p>
          <span style={{ color: "#003f85", fontWeight: "bold" }}>Yes.</span> <br />
          <br />
          The algorithm is unique, but it is based on an existing AI algorithm used by many
          companies worldwide to find information - including Google, Yahoo and Microsoft. This
          means that the results of your essay are not being stolen or copied in any way.
        </p>
        <br />
        <h3 style={{ fontSize: "20[x", lineHeight: "50px" }}>How long will it take?</h3>
        <p>
          FinishEssay takes just a couple of minutes to use. After that it takes up to 2 hours
          depending on how long your essay is and how complex your content is.
        </p>
        <br />
        <h3 style={{ fontSize: "20[x", lineHeight: "50px" }}>Is it safe and secure?</h3>
        <p>
          <span style={{ color: "#003f85", fontWeight: "bold" }}>FinishEssay is 100% Safe.</span>{" "}
          <br />
          <br /> Unlike other platforms, your essay is encrypted on our server using the Advanced
          Encryption Standard <span style={{ color: "#003f85", fontWeight: "bold" }}>
            (AES)
          </span>{" "}
          and can only be accessed by you. You can use this system to privately check the quality of
          your essay as it is being constructed, without anybody else seeing what you have written
          so far.
        </p>
      </Box>
    </div>
  );
};

export default Description;
