import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import { useAuthState } from "../context/Auth";

function AuthenticatedRoute(props) {
  const { user } = useAuthState();

  const { authenticated, guest } = props;

  // authenticated is a passed down prop from router
  // if the route needs to be authenticated

  if (authenticated && !user) {
    return <Navigate to="/authentication/sign-in" />;
  }
  if (guest && user) {
    return <Navigate to="/" />;
  }
  if (authenticated && user?.subscriptionType !== "subscription") return <Navigate to="/pricing" />;

  return <Outlet />;
}
// Setting default props for the Header
AuthenticatedRoute.defaultProps = {
  authenticated: false,
  guest: false,
};

// Typechecking props for the Header
AuthenticatedRoute.propTypes = {
  authenticated: PropTypes.bool,
  guest: PropTypes.bool,
};

export default AuthenticatedRoute;
