import "pages/Payment.css";
import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";

import image from "../../assets/download.svg";
import story from "../../assets/story.svg";
import { CreateDocument } from "apollo/mutation/DocumentMutation";

function StartCard() {
  const [errors, setErrors] = React.useState({});
  const { createDocument, createLoading } = CreateDocument(setErrors);

  const handleArticle = () => {
    createDocument({
      variables: {
        type: "article",
      },
    });
  };

  const handleStory = () => {
    createDocument({
      variables: {
        type: "story",
      },
    });
  };

  return (
    <div
      className="gradient__bg"
      style={{
        backgroundColor: "#012042",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <p style={{ padding: 10, fontFamily: "sans-serif", fontSize: "18px", color: "white" }}>
          Start a new document
        </p>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <Card sx={{ minWidth: 170, maxWidth: 200, margin: "10px", flex: 1 }}>
            <CardMedia
              style={{ borderRadius: 0, margin: 0 }}
              component="img"
              alt="New Article"
              height="100"
              src={image}
            />
            <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
              <Typography gutterBottom variant="h6" component="div">
                Start New Article
              </Typography>
              <Typography variant="body3" color="text.secondary">
                Start a new article, Brief or factual document
              </Typography>
              <CardActions>
                {createLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button onClick={handleArticle} size="small">
                      Start
                    </Button>
                    <Button size="small">More</Button>
                  </>
                )}
              </CardActions>
            </CardContent>
          </Card>
          <Card sx={{ minWidth: 170, maxWidth: 200, margin: "10px", flex: 1 }}>
            <CardMedia
              style={{ borderRadius: 0, margin: 0 }}
              component="img"
              alt="New Story"
              height="100"
              src={story}
            />
            <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
              <Typography gutterBottom variant="h6" component="div">
                Start New Story
              </Typography>
              <Typography variant="body3" color="text.secondary">
                Start a new story, Brief or non-factual document
              </Typography>
              <CardActions>
                {createLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button onClick={handleStory} size="small">
                      Start
                    </Button>
                    <Button size="small">More</Button>
                  </>
                )}
              </CardActions>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default StartCard;
