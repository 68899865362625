import "./index.css";
import React, { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import MDButton from "components/MDButton";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Sidenav({
  color,
  brand,
  brandName,
  routes,
  vars,
  setVars,
  handleWrite,
  writeLoading,
  ...rest
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  function countWords(s) {
    if (s?.length > 0) {
      s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
      s = s.replace(/[ ]{2,}/gi, " "); //2 or more space to 1
      s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
      return s.split(" ").filter(function (str) {
        return str != "";
      }).length;
    }
    //return s.split(' ').filter(String).length; - this can also be used
  }

  const words = countWords(vars?.content) ?? 0;
  const characters = vars?.content?.length ?? 0;
  const pages = parseInt(words / 500) ?? 0;

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  const marks = [
    {
      value: 0,
      label: `Small`,
    },
    {
      value: 1,
      label: "Some",
    },
    {
      value: 2,
      label: "Large",
    },
  ];

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [dragState, setDragState] = React.useState();

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={0} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="white">
            <CloseIcon />
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" alignItems="center">
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="8rem" />} */}
          <MDBox
            width={!brandName && "100%"}
            style={{ placeItems: "center", display: "flex", justifyContent: "center", height: 80 }}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              style={{ fontSize: "18px" }}
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {"FinishEssay"}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{ margin: "10px", borderRadius: "20px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Writting Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <h4>
            {pages} pages <br />
            {words} Words <br />
            {characters} Characters <br />
          </h4>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ margin: "10px", borderRadius: "20px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Article brief</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ whiteSpace: "pre-line", paddingTop: 0 }}>
          <p>
            Provide the AI with a brief of what you are writing about, describe it like you are
            speaking to a friend.
          </p>
          <TextareaAutosize
            onChange={(e) => setVars({ ...vars, background: e.target.value })}
            value={vars?.background ?? ""}
            className="article-brief"
            aria-label="empty textarea"
            placeholder=""
          />
        </AccordionDetails>
      </Accordion>
      <div style={{ backgroundColor: "white", margin: 10, borderRadius: 20, padding: 10 }}>
        <MDTypography>Output Length</MDTypography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 200 }}>
            <Slider
              className="slider-style"
              onChange={(e, v) => {
                setDragState(v);
                v = v;
              }}
              onChangeCommitted={() => setVars({ ...vars, outputLength: dragState })}
              value={vars?.outputLength ?? 1}
              defaultValue={1}
              step={1}
              marks={marks}
              min={0}
              max={2}
            />
          </Box>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        {writeLoading ? (
          <CircularProgress />
        ) : (
          <MDButton onClick={handleWrite}>Help me write</MDButton>
        )}
      </div>
      {/* <p style={{ textAlign: "center", color: "white", margin: 20 }}>Current Use(s) Left: 1</p> */}
      <MDBox p={2} mt="auto">
        <MDTypography
          textAlign="center"
          component="h6"
          variant="button"
          fontWeight="light"
          color={textColor}
        >
          © 2022 FinishEssay
        </MDTypography>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
