import React from "react";
import { setContext } from "@apollo/client/link/context";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  createHttpLink,
} from "@apollo/client";

import { SERVER_API_ADDRESS } from "../serverconf";

const httpLink = createHttpLink({
  uri: SERVER_API_ADDRESS,
});

// Function used to connect to apollo

// Note: Currently disabled Subscriptions (no subscriptions created)

const authLink = setContext((_, { headers }) => {
  // get auth token from local storage if exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// httpLink = authLink.concat(httpLink);

// Function used to connect to apollo sockets
// getting just the host address without 'HTTP://'

// const wsLink = new WebSocketLink({
//   uri: `ws://${SOCKET_API_ADDRESS}/`,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   },
// });

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === "OperationDefinition" && definition.operation === "subscription";
//   },
//   wsLink,
//   httpLink
// );

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function ApolloProvider(props) {
  return <Provider client={client} {...props} />;
}

export default ApolloProvider;
