import React from "react";
import styled, { keyframes } from "styled-components";
import Typewriter from "typewriter-effect";
import people from "../../assets/people.png";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";

// import ai from '../../assets/ai.png';
import "./header.css";
import { useNavigate } from "react-router-dom";

import FadeIn from 'react-fade-in';

const Header = () => {
  const navigate = useNavigate();
  const [signup, setSignup] = React.useState("");

  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <FadeIn animateIn="fadeInDown">
          <h1 className="gradient__text">Unlock Your Writing Potential with FinishEssay</h1>
        </FadeIn>
        <FadeIn animateIn="fadeInDown">
          <p>
            Become a better writter using FinishEssay, a new and innovative writing solution to turn
            your thoughts into well written paragraphs.
          </p>
        </FadeIn>
        <span style={{ fontWeight: "bold", color: "#20a3ff", margin: 20 }}>Get Started →</span>
        <form
          style={{ maxWidth: "500px" }}
          onSubmit={() => navigate(`/authentication/sign-up/${signup}`)}
        >
          <Box
            style={{ backgroundColor: "white" }}
            sx={{
              maxWidth: 500,
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "10px",
              display: "flex",
            }}
          >
            <InputBase
              fullWidth
              sx={{ ml: 1, flex: 1 }}
              placeholder="Email Address"
              inputProps={{ "aria-label": "Email Address" }}
              value={signup}
              onChange={(e) => setSignup(e.target.value)}
              type="email"
            />
            <button style={{ border: "0px" }} type="input">
              <span className="sign-up-button">Sign Up</span>
            </button>
          </Box>
        </form>

        {/* <div className="gpt3__header-content__people">
          <img src={people} />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div> */}
      </div>

      <div
        style={{
          backgroundColor: "rgb(4 44 84)",
          color: "white",
          flex: 1,
          borderRadius: 10,
          padding: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="gpt3__header-image">
          <Typewriter
            options={{ loop: true }}
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  'A simple yet <strong><span style="color: #20d2ff;">powerful</span></strong> typing'
                )
                .pauseFor(300)
                .deleteChars(6)
                .typeString("<strong>Writing</strong> tool for advanced leading edge ")
                .typeString("<strong>Papers</strong>")
                .pauseFor(100)
                .deleteChars(6)
                .typeString("<strong>Poems</strong>")
                .pauseFor(100)
                .deleteChars(5)
                .typeString("<strong>Research</strong>")
                .pauseFor(100)
                .deleteChars(8)
                .typeString("<strong>Reports </strong>")
                .pauseFor(100)
                .typeString(
                  'using <strong><span style="color: #27ae60;">FinishEssay</span></strong>'
                )
                .pauseFor(2500)
                .deleteAll()
                .typeString("Unlock Your Full <strong>Potential!</strong>")
                .pauseFor(1000)
                .deleteAll()
                .start();
            }}
          />
          {/* <img src={ai} /> */}
        </div>
        <strong
          style={{
            justifyContent: "end",
            alignItems: "end",
            flex: 1,
            display: "flex",
            color: "white",
            fontSize: "small",
            marginTop: "15px",
          }}
        >
          @ FinishEssay 2022
        </strong>
      </div>
    </div>
  );
};

export default Header;
