import React from "react";
import Dashboard from "./pages/Dashboard";
import Page from "pages/Page";
import Profile from "pages/Profile";
import Payment from "pages/Payment";

const routes = [
  {
    type: "collapse",
    name: "Page",
    key: "page",
    route: "/page/:id",
    component: <Page />,
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    authenticated: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    component: <Profile />,
    authenticated: false,
  },
  {
    type: "collapse",
    name: "Pricing",
    key: "pricing",
    route: "/pricing",
    component: <Payment />,
    authenticated: false,
  },
];

export default routes;
