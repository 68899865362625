import React, { createContext, useReducer, useContext } from "react";

const DocumentDispatchContext = createContext();
const DocumentStateContext = createContext();

let documents;

const documentReducer = (state, action) => {
  switch (action.type) {
    case "DOCUMENTS":
      return {
        ...state,
        documents: action.payload,
      };
    case "CREATE":
      let createDocs = JSON.parse(JSON.stringify(state.documents));
      if (createDocs) createDocs.push(action.payload);
      else createDocs = [action.payload];
      return {
        ...state,
        documents: createDocs,
      };
    case "UPDATE":
      if (state?.documents) {
        let updateDocs = JSON.parse(JSON.stringify(state.documents));
        updateDocs = updateDocs.map((obj) => [action.payload].find((o) => o.id === obj.id) || obj);
        return {
          ...state,
          documents: updateDocs,
        };
      } else {
        break;
      }
    case "DELETE":
      // return state and filter the deleted document
      return {
        ...state,
        documents: state.documents.filter((docs) => docs.id !== action.payload.id),
      };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export function DocumentProvider({ children }) {
  const [state, dispatch] = useReducer(documentReducer, { documents });

  return (
    <DocumentDispatchContext.Provider value={dispatch}>
      <DocumentStateContext.Provider value={state}>{children}</DocumentStateContext.Provider>
    </DocumentDispatchContext.Provider>
  );
}

export const useDocumentState = () => useContext(DocumentStateContext);
export const useDocumentDispatch = () => useContext(DocumentDispatchContext);
