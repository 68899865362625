import React from "react";
import possibilityImage from "../../assets/possibility.png";
import FadeIn from 'react-fade-in';
import "./possibility.css";
import { Link } from "react-router-dom";

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      {/* <img
        style={{ borderRadius: "10px" }}
        alt=""
        src="https://bestanimations.com/media/writing/1154931209tapping-pan-on-page-writitng-gif.gif"
      /> */}
    </div>
    <div className="gpt3__possibility-content">
      <FadeIn animateIn="fadeInDown">
        <h1 className="gradient__text">
          The possibilities are <br /> beyond your imagination
        </h1>
        <p>
          The possibilities are beyond your imagination. The world has never been bigger or better
          than it is right now, so there's no point in moping over missed opportunities. The world
          is constantly changing. Every day we can see new discoveries.
        </p>
      </FadeIn>
      <Link to="/authentication/sign-up">
        <h4>Sign up now to Get Started</h4>
      </Link>
    </div>
  </div>
);

export default Possibility;
