import React from "react";
import { Link } from "react-router-dom";
import Feature from "../../components/feature/Feature";
import FadeIn from 'react-fade-in';
import "./features.css";

const featuresData = [
  {
    title: "The Future of Writing",
    text: "Writing as become a highly competitive industry with each day becoming more and more difficult. How can a writer stand out from the pack? By using FinishEssay.",
  },
  {
    title: "Improved Workflow",
    text: "With FinishEssay, writers are able to focus on the writing process and not worry about the dificulties in mental block.",
  },
  {
    title: "Built in Genre",
    text: "FinishEssay is built to help any kind of writer. From bloggers, journalists and creatives to business, SEO and more.",
  },
  {
    title: "Stand Out From the Crowd",
    text: "Good writing is profitable and well received. Customers will be more likely to buy from you.",
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">
        The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.
      </h1>
      <Link to="/authentication/sign-up">
        <p>Sign up now to Get Started</p>
      </Link>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <FadeIn style={{ transition: "1.5s ease all" }} animateIn="fadeIn">
          <Feature title={item.title} text={item.text} key={item.title + index} />
        </FadeIn>
      ))}
    </div>
  </div>
);

export default Features;
